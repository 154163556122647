<template>
    <div class="Mail">
        <div v-if="isLoading" class="loadingBox">
        <loading-new :loading="isLoading" class="loading-center-absolute" :show1="true" v-if="isLoading"></loading-new>
            <div class="loadingText">
                首次进入邮件模块<br>配置数据加载中...<br>请稍等
            </div>
        </div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
import titleMixin from '../../mixin/title'
import loadingNew from '@/page/Mail/MailPublic/LoadingVue/Vue/loading3'

export default {
    name: 'Mail',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Mail.1584681526518') }, // '孚盟邮'
    data() {
        return {
            msg: 'Mail',
            isLoading: true
        }
    },
    mounted() {
       this.isLoading = false
    },
    created() {
        this.toPage()
    },
    methods: {
        toPage() {
            if (this.$route.path === '/mail' || this.$route.path === '/mail/') {
                console.log(this.$route)
                this.$router.replace({path: '/mail/home', query: {...this.$route.query }})
            }
        }
    },
    watch: {
        $route() {
            this.toPage()
        }
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>
<style lang="less">
.Mail {
    height: 100%;
    width: 100%;
    background: #ffffff;
    user-select: none;
}

.loadingBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    .loadingText {
        .width(160px);
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}

.loading-center-absolute{
    .height(20px);
    .loadingText {
        .left(-40px);
        .top(80px);
        position: absolute;
        .width(160px);
        margin: 0 auto;
        text-align: center;
        .font-size(12px);
        color: #909399;
    }
}
</style>
